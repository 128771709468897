import DynamicFeedIcon from "@mui/icons-material/DynamicFeed"
import ShareIcon from "@mui/icons-material/Share"
import { Box } from "@mui/material"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../AppContext"
import { Collection, NFT } from "../../core/api"
import AlertContainer from "../../core/store/AlertContainer"
import { AssetContext } from "../../core/store/AssetContext"
import { formatEndOfLineToHtml } from "../../core/utils/convertEndOfLine"
import { LineShareUrlPrefix } from "../../core/utils/getCustomConfig"
import { PrimaryButton } from "../common/PrimaryButton"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import VisibilityIcon from "@mui/icons-material/Visibility"
import FavoriteIcon from "@mui/icons-material/Favorite"
import { AssetSubTitle } from "./AssetSubTitle"

export type AssetTopBarProps = {
  detail: NFT
  nftCollection?: Collection
  walletAddress: string
}

export const AssetTopBar: React.FunctionComponent<AssetTopBarProps> = ({
  detail,
  nftCollection,
  walletAddress,
}) => {
  const { t } = useTranslation()
  const { openBurnModal, openTransferModal } = useContext(AssetContext)
  const [favoriteCount, setFavoriteCount] = useState(detail.favorite || 0)
  const [favoriteStatus, setFavoriteStatus] = useState(detail.isFavorite)
  const { pushAlert } = AlertContainer.useContainer()

  const shareLink = () => {
    typeof window !== "undefined" &&
      window.open(`${LineShareUrlPrefix}${window.location.href}`, "blank")
  }

  const addFavorite = async () => {
    if (walletAddress !== "") {
      try {
        const res = await AppContext.apiExecutor.nftAddFavoritePut({
          address: detail.address,
          id: detail.tokenId,
          walletAddress: walletAddress,
        })

        setFavoriteCount(
          Number.isFinite(res.data.favorite)
            ? res.data.favorite || 0
            : favoriteCount
        )
        setFavoriteStatus(res.data.isFavorited || false)
      } catch (err) {
        console.info("err: ", err)
        pushAlert({
          message: "Added Failed",
          severity: "error",
        })
      }
    }
  }

  const externalUrl = () => {
    typeof window !== "undefined" &&
      window.open(detail.metadata?.external_url || "", "blank")
  }

  return (
    <div className="c-nft-detail__head justify-between py-7 lg:flex">
      <div className="px-7 lg:w-1/2">
        <div className="c-nft-detail__media">
          <img
            className="mb-0"
            src={detail.image}
            alt={detail.metadata?.name}
          />
        </div>
        <div className="c-nft-detail__info">
          <Box
            className="c-nft-detail__info--item like active inline-flex cursor-pointer items-center"
            sx={{
              "&:hover": { color: "primary.main" },
              "&:hover svg": { color: favoriteStatus ? "" : "primary.main" },
            }}
            onClick={() => addFavorite()}
          >
            <FavoriteIcon
              sx={{
                color: favoriteStatus ? "error.main" : "text.secondary",
                fontSize: 22,
              }}
              className="mr-1"
            />
            <span>{favoriteCount}</span>
          </Box>
          <div className="c-nft-detail__info--item view inline-flex items-center">
            <VisibilityIcon className="mr-1" sx={{ fontSize: 22 }} />
            <span>{detail.trend}</span>
          </div>
          {detail.metadata?.external_url && (
            <Box
              className="c-nft-detail__info--item link inline-flex cursor-pointer items-center"
              sx={{ "&:hover": { color: "primary.main" } }}
              onClick={() => externalUrl()}
            >
              <OpenInNewIcon className="mr-1" sx={{ fontSize: 22 }} />
              <span>{t("str_link")}</span>
            </Box>
          )}
          <Box
            className="c-nft-detail__info--item share inline-flex cursor-pointer items-center"
            sx={{ "&:hover": { color: "primary.main" } }}
            onClick={() => shareLink()}
          >
            <ShareIcon className="mr-1" sx={{ fontSize: 22 }} />
            <span>{t("str_share")}</span>
          </Box>
        </div>
      </div>
      <div className="pr-5 lg:w-1/2">
        <div className="c-nft-detail__ttl">
          <Box
            className="c-nft-detail__ttl--cat"
            sx={{ color: "primary.main" }}
          >
            {nftCollection?.name || ""}
          </Box>
          {detail.metadata?.name || ""}
        </div>
        <div className="c-nft-detail__transfer mb-6 pl-5">
          <PrimaryButton
            sx={{
              height: 50,
              width: "100%",
              fontSize: 18,
              maxWidth: 180,
              fontWeight: "normal",
            }}
            className="!my-2 lg:!mr-4"
            variant="contained"
            onClick={() => openTransferModal(detail)}
          >
            {t("str_nft_transfer")}
          </PrimaryButton>
          <PrimaryButton
            sx={{
              height: 50,
              width: "100%",
              fontSize: 18,
              maxWidth: 180,
              borderWidth: 2,
              fontWeight: "normal",
            }}
            className="!my-2"
            variant="outlined"
            onClick={() => openBurnModal(detail)}
          >
            {t("str_nft_burn")}
          </PrimaryButton>
        </div>
        <div className="c-list-group ml-5 !rounded-[1px]">
          <AssetSubTitle
            title={t("str_description")}
            icon={<DynamicFeedIcon className="mr-2" sx={{ fontSize: 18 }} />}
          />
          <div className="c-list-group__item">
            {formatEndOfLineToHtml(detail.metadata?.description || "")}
          </div>
        </div>
      </div>
    </div>
  )
}
