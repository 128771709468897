import CampaignIcon from "@mui/icons-material/Campaign"
import { Box } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useCopyToClipboard } from "react-use"
import { Collection, NewCollectionBlockchainEnum, NFT } from "../../core/api"
import { blockchainStandard } from "../../core/const/blockchain"
import AlertContainer from "../../core/store/AlertContainer"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { AssetSubTitle } from "./AssetSubTitle"

export type AssetInformationProps = { detail: NFT; nftCollection?: Collection }

export const AssetInformation: React.FunctionComponent<AssetInformationProps> =
  ({ detail, nftCollection }) => {
    const { t } = useTranslation()
    const [state, copyToClipboard] = useCopyToClipboard()
    const { pushAlert } = AlertContainer.useContainer()

    const copyNFTAddress = () => {
      copyToClipboard(detail.address || "")
      pushAlert({
        message: t("str_copy_success"),
        severity: "success",
      })
    }

    const renderContent = (
      subTitle: string,
      children: JSX.Element | string | number
    ): JSX.Element => {
      return (
        <div>
          <p className="data-name">{subTitle}</p>
          <p className="data-detail">{children}</p>
        </div>
      )
    }

    return (
      <div className="c-list-group">
        <AssetSubTitle
          title={t("str_description")}
          icon={<CampaignIcon className="mr-2" sx={{ fontSize: 18 }} />}
        />
        <div className="c-list-group__item infomation">
          {renderContent(t("str_owner"), detail.owner || "")}
          <div>
            <p className="data-name">{t("str_contract_address")}</p>
            <Box
              className="data-detail inline-flex cursor-pointer items-center justify-end"
              onClick={() => copyNFTAddress()}
              sx={{ "&:hover": { color: "primary.main" } }}
            >
              {detail.address}
              <ContentCopyIcon fontSize="small" className="ml-2" />
            </Box>
          </div>
          {renderContent(t("str_token_id"), detail.tokenId || "")}
          {renderContent(
            t("str_token_standard"),
            blockchainStandard[
              detail.metadata?.blockchain as NewCollectionBlockchainEnum
            ]
          )}
          {renderContent(
            t("str_blockchain"),
            detail.metadata?.blockchain || ""
          )}
          {renderContent(
            t("str_category"),
            nftCollection && nftCollection.category
              ? nftCollection.category
              : ""
          )}
        </div>
      </div>
    )
  }
