import { NewCollectionBlockchainEnum } from "../api"

export const blockchainCoinUnit: Record<NewCollectionBlockchainEnum, string> = {
  [NewCollectionBlockchainEnum.Ethereum]: "ETH",
  [NewCollectionBlockchainEnum.Polygon]: "MATIC",
  [NewCollectionBlockchainEnum.Diver]: "DIV",
}

export const blockchainStandard: Record<NewCollectionBlockchainEnum, string> = {
  [NewCollectionBlockchainEnum.Ethereum]: "ERC-1155",
  [NewCollectionBlockchainEnum.Polygon]: "ERC-1155",
  [NewCollectionBlockchainEnum.Diver]: "DRC-1155",
}

export const blockchainIdCoinUnit: Record<number, string> = {
  1: "ETH",
  3: "ETH",
  137: "MATIC",
  80001: "MATIC",
  556677: "DIV",
  5566: "DIV",
}

export const blockchainIdCoinType: Record<number, NewCollectionBlockchainEnum> =
  {
    1: NewCollectionBlockchainEnum.Ethereum,
    3: NewCollectionBlockchainEnum.Ethereum,
    137: NewCollectionBlockchainEnum.Polygon,
    80001: NewCollectionBlockchainEnum.Polygon,
    556677: NewCollectionBlockchainEnum.Diver,
    5566: NewCollectionBlockchainEnum.Diver,
  }
