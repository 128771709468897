import React from "react"
import { NewCollectionBlockchainEnum } from "../../core/api"
import { AssetInfo } from "../../core/type/Response"
import { AssetActivity } from "./AssetActivity"
import { AssetInformation } from "./AssetInformation"
import { AssetTopBar } from "./AssetTopBar"

export type AssetDetailProps = {
  nftData: AssetInfo
  walletAddress: string
}

export const AssetDetail: React.FunctionComponent<AssetDetailProps> = ({
  nftData,
  walletAddress,
}) => {
  const nftCollection = nftData.collections.find(
    item => item.collectionID === nftData.detail.metadata?.collectionID
  )

  return (
    <div className="c-main">
      <div className="c-container mx-auto px-5">
        <div className="c-nft-detail">
          <AssetTopBar
            detail={nftData.detail}
            nftCollection={nftCollection}
            walletAddress={walletAddress}
          />
          <div className="c-nft-detail__body md:mt-3 md:px-10">
            <AssetActivity
              activity={nftData.activity || []}
              blockchain={
                nftData.detail.metadata
                  ?.blockchain as NewCollectionBlockchainEnum
              }
            />
            <AssetInformation
              detail={nftData.detail}
              nftCollection={nftCollection}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
