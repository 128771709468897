import { Box, useTheme } from "@mui/material"
import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import { ReactSVG } from "react-svg"
import {
  NewCollectionBlockchainEnum,
  NftActivity,
  NftActivityEventEnum,
} from "../../core/api"
import CompareArrowsIcon from "@mui/icons-material/CompareArrows"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import ConstructionIcon from "@mui/icons-material/Construction"
import RepeatIcon from "@mui/icons-material/Repeat"
import StorefrontIcon from "@mui/icons-material/Storefront"
import { blockchainCoinUnit } from "../../core/const/blockchain"
import { AssetSubTitle } from "./AssetSubTitle"

export type AssetActivityProps = {
  activity: Array<NftActivity>
  blockchain: NewCollectionBlockchainEnum
}

export const AssetActivity: React.FunctionComponent<AssetActivityProps> = ({
  activity,
  blockchain,
}) => {
  const { t } = useTranslation()
  const { palette } = useTheme()
  const renderEventIcon = (event: NftActivityEventEnum): JSX.Element => {
    let iconElement = <></>
    switch (event) {
      case NftActivityEventEnum.Transfer:
        iconElement = (
          <CompareArrowsIcon className="mr-2" sx={{ fontSize: 18 }} />
        )
        break
      case NftActivityEventEnum.Buy:
        iconElement = (
          <ShoppingCartIcon className="mr-2" sx={{ fontSize: 18 }} />
        )
        break
      case NftActivityEventEnum.Mint:
        iconElement = (
          <ConstructionIcon className="mr-2" sx={{ fontSize: 18 }} />
        )
        break
      case NftActivityEventEnum.Sale:
      case NftActivityEventEnum.Resell:
        iconElement = <StorefrontIcon className="mr-2" sx={{ fontSize: 18 }} />
        break
      default:
        iconElement = <></>
        break
    }
    return (
      <div className="flex items-center">
        {iconElement}
        <span className="inline-block">{event}</span>
      </div>
    )
  }
  return (
    <div className="c-list-group">
      <AssetSubTitle
        title={t("str_item_activity")}
        icon={<RepeatIcon className="mr-2" sx={{ fontSize: 18 }} />}
      />
      <div className="flex w-full flex-col">
        <div className="text-caption1 flex h-10 w-full items-center px-4 font-bold">
          <div className="flex-1 md:w-1/5">{t("str_event")}</div>
          <div className="w-1/5">{t("str_price")}</div>
          <div className="hidden w-1/5 md:inline-block">
            {t("str_nft_from")}
          </div>
          <div className="hidden w-1/5 md:inline-block">{t("str_nft_to")}</div>
          <div className="w-1/5 text-right">{t("str_date")}</div>
        </div>
        {activity.map((item, index) => (
          <Box
            key={index}
            sx={{ backgroundColor: palette.background.paper }}
            className="text-caption1 mb-2 flex h-10 w-full items-center rounded-md px-4"
          >
            <div className="flex-1 md:w-1/5">
              {renderEventIcon(item.event as NftActivityEventEnum)}
            </div>
            <div className="flex w-1/5 items-center">
              <ReactSVG
                src={`/images/crypto/${
                  blockchain as NewCollectionBlockchainEnum
                }.svg`}
                className="mr-2 w-[10px]"
              />
              {`${item.unitPrice} ${
                blockchainCoinUnit[blockchain as NewCollectionBlockchainEnum]
              }`}
            </div>
            <Box
              sx={{ color: palette.text.secondary }}
              className="hidden w-1/5 truncate pr-5 md:inline-block"
            >
              {item.from !== "0" && item.from}
            </Box>
            <Box
              sx={{ color: palette.text.secondary }}
              className="hidden w-1/5 truncate pr-5 md:inline-block"
            >
              {item.to}
            </Box>
            <div className="w-1/5 min-w-[75px] text-right">
              {item.date && format(new Date(item.date * 1000), "yyyy/MM/dd")}
            </div>
          </Box>
        ))}
      </div>
    </div>
  )
}
