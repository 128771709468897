import { ethers } from "ethers"
import { navigate } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { useAsync } from "react-use"
import { AppContext } from "../../AppContext"
import abi from "../../asset/abi.json"
import {
  InlineObject5ActionEnum,
  InlineObject5BlockchainEnum,
  NFT,
} from "../../core/api"
import { DialogTypeEnum } from "../../core/const/DialogTypeEnum"
import AlertContainer from "../../core/store/AlertContainer"
import WalletContainer from "../../core/store/WalletContainer"
import { AssetInfo } from "../../core/type/Response"
import { RPCError } from "../../core/type/RPCResponse"
import { connectProvider } from "../../core/utils/connectProvider"
import { generateRPCErrorMsg } from "../../core/utils/generateRPCErrorMsg"
import {
  getContractAddress,
  getCustomConfig,
} from "../../core/utils/getCustomConfig"
import { validationNetwork } from "../../core/utils/validationNetwork"
import { LoadingWrapper } from "../common/LoadingWrapper"
import { AssetDetail } from "./AssetDetail"
import { TransferNFT } from "./popup/TransferNFT"
import { AssetContext } from "../../core/store/AssetContext"

export type AssetContainerProps = {
  address: string
  tokenId: string
}

export const AssetContainer: React.FunctionComponent<AssetContainerProps> = ({
  address,
  tokenId,
}) => {
  const { t } = useTranslation()
  const { pushAlert, openModal, closeModal } = AlertContainer.useContainer()
  const { walletAddress, chainId } = WalletContainer.useContainer()

  const nftData = useAsync(async () => {
    if (address && tokenId && Number.isFinite(+tokenId)) {
      const NFTRequest = {
        address: address,
        tokenId: +tokenId,
      }
      return Promise.all([
        AppContext.apiExecutor.nftDetailPost({
          data: [NFTRequest],
          walletAddress: walletAddress,
        }),
        AppContext.apiExecutor.nftPriceHistoryGet(address, +tokenId),
        AppContext.apiExecutor.nftActivityGet(address, +tokenId),
        // AppContext.apiExecutor.nftAddressTokenidOfferHistoryGet(
        //   address,
        //   tokenId
        // ), // TODO OfferHistory
        AppContext.apiExecutor.collectionOwnedPost({
          walletAddress: walletAddress,
        }),
        AppContext.apiExecutor.nftTrendPut({ address: address }).catch(),
      ])
        .then(([detail, history, activity, collections]) => {
          const nftDetail = detail.data.data ? detail.data.data[0] : {}
          const activityDetail = activity.data.data || []
          return {
            detail: nftDetail,
            sellingRecord: {
              price: nftDetail?.price || "",
              owner: nftDetail?.owner || "",
              isSelling: nftDetail?.sellable || false,
              isOwner: nftDetail.owner
                ? nftDetail.owner.toLowerCase() === walletAddress.toLowerCase()
                : false,
            },
            priceHistory: history.data.data,
            activity: activityDetail,
            // offerHistory: offerHistory.data.data, // TODO OfferHistory
            offerHistory: [],
            collections: collections.data.data || [],
          }
        })
        .catch(err => {
          console.info("err: ", err)
          pushAlert({
            message: "can't get NFT information",
            severity: "error",
          })
        })
    } else {
      pushAlert({
        message: "can't found tokenId",
        severity: "error",
      })
    }
  }, [])

  const burn = async (detail: NFT) => {
    try {
      if (
        validationNetwork(
          chainId,
          detail.metadata?.blockchain as InlineObject5BlockchainEnum
        )
      ) {
        const provider = await connectProvider()
        const signer = provider.getSigner()
        await signer.signMessage(getCustomConfig().burnSignMessage)
        const supply = (1).toString() // TODO detail.supply
        const contract = new ethers.Contract(
          getContractAddress(
            detail.metadata?.blockchain as InlineObject5BlockchainEnum
          ),
          abi,
          signer
        )

        const tx = await contract.burn(detail.address, detail.tokenId, supply)
        console.info("tx", tx)

        await AppContext.apiExecutor.nftTransactionhashPost({
          walletAddress: walletAddress || "",
          collectionID:
            detail.metadata && detail.metadata.collectionID
              ? detail.metadata.collectionID
              : 0,
          blockchain: detail.metadata
            ?.blockchain as InlineObject5BlockchainEnum,
          transactionHash: tx.hash,
          action: InlineObject5ActionEnum.Burn,
        })
        openModal({
          title: t("str_burn_success"),
          content: t("str_burn_success_message"),
          type: DialogTypeEnum.SUCCESS,
          close: closeModal,
          buttonRight: {
            text: t("str_goto_account"),
            action: () => navigate("/account"),
          },
        })
      } else {
        openModal({
          title: t("str_network_error_title"),
          content: t("str_network_error_message", {
            chainId: chainId,
            blockchain: detail.metadata?.blockchain,
          }),
          type: DialogTypeEnum.WARNING,
          close: closeModal,
          buttonRight: { text: t("str_network_error_confirmBtn") },
        })
      }
    } catch (err) {
      pushAlert({
        message: generateRPCErrorMsg(err as RPCError),
        severity: "error",
      })
      console.info("err: ", err)
    }
  }

  const openBurnModal = (NFT: NFT) => {
    openModal({
      title: t("str_nft_burn"),
      content: (
        <>
          <div className="mb-4 text-center">{t("str_burn_content")}</div>
          <div className="text-center text-error">{t("str_burn_hint")}</div>
        </>
      ),
      type: DialogTypeEnum.WARNING,
      buttonRight: {
        text: t("str_burn_btn"),
        action: () => burn(NFT),
      },
    })
  }

  const openTransferModal = (NFT: NFT) => {
    if (
      validationNetwork(
        chainId,
        NFT.metadata?.blockchain as InlineObject5BlockchainEnum
      )
    ) {
      openModal({
        children: (
          <TransferNFT
            item={NFT}
            walletAddress={walletAddress}
            closeModal={closeModal}
          />
        ),
      })
    } else {
      openModal({
        title: t("str_network_error_title"),
        content: t("str_network_error_message", {
          chainId: chainId,
          blockchain: NFT.metadata?.blockchain,
        }),
        type: DialogTypeEnum.WARNING,
        close: closeModal,
        buttonRight: { text: t("str_network_error_confirmBtn") },
      })
    }
  }

  return (
    <AssetContext.Provider
      value={{
        openBurnModal,
        openTransferModal,
        walletAddress,
      }}
    >
      <LoadingWrapper
        state={nftData}
        keepLoading={data => !data}
        render={nftData => (
          <AssetDetail
            nftData={nftData as unknown as AssetInfo}
            walletAddress={walletAddress}
          />
        )}
      />
    </AssetContext.Provider>
  )
}
