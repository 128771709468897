import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Asset } from "../../components/asset/Asset"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { getCustomConfig } from "../../core/utils/getCustomConfig"

export type AssetProps = {
  address: string
  tokenId: string
} & FunctionComponent

export default function AssetPage(props: AssetProps): JSX.Element {
  const tokenId = props["tokenId"]
  const address = props["address"]
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo
        title={`${t("str_nft_detail")} | ${getCustomConfig().title_suffix}`}
        favicon={getCustomConfig().favicon}
      />
      <Asset tokenId={tokenId} address={address} />
    </Layout>
  )
}
