import React from "react"
import { MainLayout } from "../common/MainLayout"
import { AssetContainer } from "./AssetContainer"

export type AssetProps = {
  address: string
  tokenId: string
}

export const Asset: React.FunctionComponent<AssetProps> = ({
  address,
  tokenId,
}) => {
  return (
    <MainLayout>
      <AssetContainer tokenId={tokenId} address={address} />
    </MainLayout>
  )
}
