import { Box } from "@mui/material"
import React from "react"

export type AssetSubTitleProps = {
  title: string
  icon: JSX.Element
}

export const AssetSubTitle: React.FunctionComponent<AssetSubTitleProps> = ({
  title,
  icon,
}) => {
  return (
    <Box
      className="c-list-group__head flex items-center"
      sx={{ backgroundColor: "primary.light" }}
    >
      {icon}
      <span>{title}</span>
    </Box>
  )
}
