import { yupResolver } from "@hookform/resolvers/yup"
import { ethers } from "ethers"
import { navigate } from "gatsby"
import React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../../AppContext"
import abi from "../../../asset/abi.json"
import {
  InlineObject5ActionEnum,
  InlineObject5BlockchainEnum,
  NFT,
} from "../../../core/api"
import { DialogTypeEnum } from "../../../core/const/DialogTypeEnum"
import { transferFormSchema } from "../../../core/const/formSchema"
import { initialTransferForm } from "../../../core/const/initialFormData"
import AlertContainer from "../../../core/store/AlertContainer"
import { transferNFTRequest } from "../../../core/type/Request"
import { RPCError } from "../../../core/type/RPCResponse"
import { connectProvider } from "../../../core/utils/connectProvider"
import { generateRPCErrorMsg } from "../../../core/utils/generateRPCErrorMsg"
import {
  getContractAddress,
  getCustomConfig,
} from "../../../core/utils/getCustomConfig"
import { CommonTextField } from "../../common/form/CommonTextField"
import { PrimaryButton } from "../../common/PrimaryButton"

export type TransferNFTProps = {
  item: NFT
  walletAddress: string
  closeModal: () => void
}

export const TransferNFT: React.FunctionComponent<TransferNFTProps> = ({
  item,
  walletAddress,
  closeModal,
}) => {
  const { t } = useTranslation()
  const { openModal, pushAlert } = AlertContainer.useContainer()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<transferNFTRequest>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(transferFormSchema),
    defaultValues: {
      ...initialTransferForm,
      walletAddress: walletAddress,
    },
  })

  const transfer = async (form: transferNFTRequest) => {
    try {
      // initial
      console.info("form: ", form)
      const provider = await connectProvider()
      const signer = provider.getSigner()
      await signer.signMessage(getCustomConfig().transferSignMessage)
      const contract = new ethers.Contract(
        getContractAddress(
          item.metadata?.blockchain as InlineObject5BlockchainEnum
        ),
        abi,
        signer
      )
      const supply = (1).toString()

      // ethers
      const tx = await contract.transferTo(
        item.address,
        item.tokenId,
        form.transferTo,
        supply
      )
      console.info("tx", tx)
      // upload Transaction Hash
      await AppContext.apiExecutor.nftTransactionhashPost({
        walletAddress: walletAddress || "",
        collectionID:
          item.metadata && item.metadata.collectionID
            ? item.metadata.collectionID
            : 0,
        blockchain: item.metadata?.blockchain as InlineObject5BlockchainEnum,
        transactionHash: tx.hash,
        action: InlineObject5ActionEnum.Transfer,
      })
      openModal({
        title: t("str_transfer_success"),
        content: t("str_transfer_success_message"),
        type: DialogTypeEnum.SUCCESS,
        close: closeModal,
        buttonRight: {
          text: t("str_goto_account"),
          action: () => navigate("/account"),
        },
      })
    } catch (err) {
      pushAlert({
        message: generateRPCErrorMsg(err as RPCError),
        severity: "error",
      })
      console.info("err: ", err)
    }
  }

  return (
    <div className="flex max-w-[90vw] flex-col py-4 lg:w-[560px]">
      <p className="text-h3 mb-6 border-b font-bold font-medium">
        {t("str_nft_transfer")}
      </p>
      <div className="mb-3 text-center">
        <p className="text-h5 form-required mb-1 flex items-center font-medium">
          {t("str_wallet_address")}
        </p>
        <CommonTextField
          type="text"
          placeholder={t("str_wallet_address_placeholder")}
          fullWidth
          variant="outlined"
          control={control}
          name="transferTo"
          errors={errors}
        />
      </div>
      <div className="flex flex-1 flex-row justify-center">
        <PrimaryButton
          className="!mt-2 !normal-case"
          onClick={handleSubmit(transfer)}
          variant="contained"
        >
          {t("str_transfer")}
        </PrimaryButton>
      </div>
    </div>
  )
}
